@import "fonts";
@import "reset";
@import "media-queries";
@import "variables";
@import "mixins";
@import "flickity";
@import "form";
@import "newsletter-form";

/* GLOBAL
---------------------------------------------- */
html {
  scroll-behavior: smooth;
}
body {
  font-family: $body-font;
  font-size: 1.14rem;
  color: $black;
  -webkit-font-smoothing: antialiased;
}
/* .uniform__potty {
  position: absolute;
  left: -9999px;
} */
.visually-hidden {
  position:absolute !important;
  overflow:hidden;
  width:1px;
  height:1px;
  margin:-1px;
  padding:0;
  border:0;
  clip:rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap:normal !important
}
.hidden-on-mobile {
  display: block;
}
.hidden-on-desktop {
  display: none;
}
.center {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
p, .paragraph {
  font-size: 1.14rem;
}
.italic {
  font-style: italic;
}
h1, h2, h3, h4, h5, .heading-font {
  font-family: $heading-font;
  line-height: 98.5%;
}
.body-font {
  font-family: $body-font;
}
.medium-font-size, .medium-font-size p {
  font-size: var(--medium-font-size);
}
.large-font-size, .large-font-size p {
  font-size: var(--large-font-size);
}
.x-large-font-size, .x-large-font-size p {
  font-size: var(--x-large-font-size);
}
.xx-large-font-size {
  font-size: var(--xx-large-font-size);
}
.underlined, .button a, .contact-template .formblock__submit>input {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: $black;
  text-decoration-thickness: 1px;
  transition: text-decoration-color .4s ease;
  &:hover{
    text-decoration-color: rgb(0, 0, 0, 0);
  }
}
.underlined-on-hover {
  a {
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-color: rgb(0, 0, 0, 0);
    text-decoration-thickness: 1px;
    transition: text-decoration-color .4s ease;
    &:hover{
      text-decoration-color: $black;
    }
  }
}
.wide-heading {
  max-width: 850px;
}
.thin-heading {
  max-width: 400px;
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
.small-vertical-margin {
  margin-top: var(--small-vertical-margin);
  margin-bottom: var(--small-vertical-margin);
}
.medium-vertical-margin {
  margin-top: var(--medium-vertical-margin);
  margin-bottom: var(--medium-vertical-margin);
}
.large-vertical-margin {
  margin-top: var(--large-vertical-margin);
  margin-bottom: var(--large-vertical-margin);
}
.x-large-vertical-margin {
  margin-top: var(--x-large-vertical-margin);
  margin-bottom: var(--x-large-vertical-margin);
}
.small-top-padding {
  padding-top: var(--small-vertical-margin);
}
.medium-top-padding {
  padding-top: var(--medium-vertical-margin);
}
.large-top-padding {
  padding-top: var(--large-vertical-margin);
}
.x-large-top-padding {
  padding-top: var(--x-large-vertical-margin);
}
.small-bottom-padding {
  padding-bottom: var(--small-vertical-margin);
}
.medium-bottom-padding {
  padding-bottom: var(--medium-vertical-margin);
}
.large-bottom-padding {
  padding-bottom: var(--large-vertical-margin);
}
.x-large-bottom-padding {
  padding-bottom: var(--x-large-vertical-margin);
}
.media-left {
  >div:first-of-type {
    order: 0;
  }
  .media-padding {
    padding: var(--lr-page-padding);
    padding-right: 0px;
    @include for-small-tablet-below {
      padding: var(--lr-page-padding);
    }
  }
} 
.media-right {
  >div:first-of-type {
    order: 1;
  }
  .media-padding {
    padding: var(--lr-page-padding);
    padding-left: 0px;
    @include for-small-tablet-below {
      padding: var(--lr-page-padding);
    }
  }
}
.square-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(var(--lr-page-padding)/2);
  @include for-small-tablet-below {
    grid-template-columns: 1fr;
    gap: calc(var(--lr-page-padding)/1);
  }
}

.background-color-d3cdba {
  $btn-bkg: #d3cdba;
  .square-button {
    a {
      &:hover {
        background: lighten($btn-bkg, 5%)
      }
    }
  }
}
.background-color-dad5c6 {
  $btn-bkg: #dad5c6;
  .square-button {
    a {
      &:hover {
        background: lighten($btn-bkg, 5%)
      }
    }
  }
}
.background-color-e2e0db {
  $btn-bkg: #e2e0db;
  .square-button {
    a {
      &:hover {
        background: lighten($btn-bkg, 5%)
      }
    }
  }
}
.background-color-e9e6dd {
  $btn-bkg: #e9e6dd;
  .square-button {
    a {
      &:hover {
        background: lighten($btn-bkg, 5%)
      }
    }
  }
}
.background-color-ecece5 {
  $btn-bkg: #ecece5;
  .square-button {
    a {
      &:hover {
        background: lighten($btn-bkg, 5%)
      }
    }
  }
}
.background-color-f0efe2 {
  $btn-bkg: #f0efe2;
  .square-button {
    a {
      &:hover {
        background: lighten($btn-bkg, 5%)
      }
    }
  }
}
.background-color-f9f6ed {
  $btn-bkg: #f9f6ed;
  .square-button {
    a {
      &:hover {
        background: lighten($btn-bkg, 5%)
      }
    }
  }
}
.background-color-fbf9f4 {
  $btn-bkg: #fbf9f4;
  .square-button {
    a {
      &:hover {
        background: lighten($btn-bkg, 5%)
      }
    }
  }
}

#menu-button {
  padding: 12px;
  display: none;
  @include for-small-tablet-below {
    // padding-top: initial;
    display: block;
  }
}

#menu-modal {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: $black;
  transition: left 0.4s ease-in-out;
  color: $white;
  overflow: scroll;
  .menu-modal-top-bar {
    display: flex;
    justify-content: space-between;
    margin: 0 calc(var(--lr-page-padding) / 2);
  }
  .logo-container {
    top: initial;
    left: initial;
    position: relative;
    transform: initial;
    margin: 0;
    a {
      display: block;
      padding: 12px;
      .logo {
        margin-top: 4px;
      }
      path {
        stroke: #fff;
      }
    }
  }

}

#menu-modal.hidden {
  left: -100%;
}

#menu-modal.active {
  left: 0;
}

#close {
  display: block;
  padding: 12px;
  text-transform: uppercase;
}

.menu-modal-body {
  padding: var(--x-large-vertical-margin) var(--lr-page-padding);
  font-family: $heading-font;
  font-size: var(--x-large-font-size);
  li a {
    display: block;
    padding: .3rem 0;
    text-align: center;
  }
}
.menu-modal-footer {
  padding: 0 var(--lr-page-padding) var(--small-vertical-margin) var(--lr-page-padding);
  .footer-menus {
    border-top: 1px solid $white;
    display: flex;
    flex-direction: column;
    grid-gap: calc(var(--lr-page-padding) * 2);
    .footer-menu:first-of-type {
      padding-top: var(--medium-vertical-margin);
    }
  }
}

/* NAVBAR
---------------------------------------------- */
.home-template header, .about-template header, .default-template .navbar-wrapper--light {
  position: absolute;
  width: 100%;
  z-index: 5;
  .logo {
    margin-top: 4px;
  }
}.about-template header, .default-template .navbar-wrapper--light {
  .navbar {
    color: $white;
  }
  .logo {
    & path {
      stroke: $white;
    }
  }
  .underlined-on-hover {
    a {
      text-decoration-color: rgba(255, 255, 255, 0);
      &:hover{
        text-decoration-color: $white;
      }
    }
  }
}
.navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 calc(var(--lr-page-padding) / 2);
  @include for-small-tablet-below {

   }
  ul {
    display: flex;
    flex-direction: row;
    @include for-small-tablet-below {
      display: none;
    }
    &:first-of-type {
      justify-content: start;
      flex-grow: 1;
    }
    &:last-of-type {
      justify-content: end;
      flex-grow: 1;
    }
    a {
      display: block;
      padding: 12px calc(var(--lr-page-padding) / 2);
    }
  }
  .logo-container {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include for-small-tablet-below {
      top: initial;
      left: initial;
      position: relative;
      transform: initial;
    }
  }
  .logo {
    margin-top: 4px;
    & path {
      stroke: $black;
    }
  } 
}

/* BLOCKS
---------------------------------------------- */
.homepage-banner {
  .background-texture {
    position: relative;
    height: 100vh;
    img {
      object-fit: cover;
    }
    .text-overlay {
      position: absolute;
      bottom: 0px;
      width: 100%;
      .inline-large-text {
        padding: var(--lr-page-padding) var(--lr-page-padding) 0 var(--lr-page-padding);
        margin-bottom: 28vh;
        @include for-small-tablet-below {
          margin-bottom: 11rem;
        }
        p {
          font-family: $heading-font;
          font-size: 6vw;
          line-height: 1.2;
          display: inline;
          @include for-small-tablet-below {
            font-size: var(--x-large-font-size);
          }
        }
        .second-line {
          padding-left: 15vw;
        }
        .inset-image {
          display: inline-block;
          width: 6vw;
          height: auto;
          position: relative;
          margin: 0 2rem;
          margin-bottom: -2.5rem;
          @include for-small-tablet-below {
            margin: 0 2rem;
            margin-bottom: -1.5rem;
            width: 10vw;
            display: none;
          }
          img {
            height: auto;
          }
          &:nth-of-type(2) {
            width: 9vw;
            display: inline-block;
            margin-top: 1rem;
            margin-bottom: 0rem;
            @include for-small-tablet-below {
              display: none;
            }
          }
        }
      }
    }
  }
  .banner-info-wrapper {
    display: flex;
    padding: var(--lr-page-padding);
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: center;
    @include for-small-tablet-below {
      position: relative;
      flex-direction: column;
      gap: calc(var(--lr-page-padding))
    }
  }
  .banner-info {
    display: flex;
    padding: .4rem 1rem;
    border: 1px solid $black;
    transition: .4s;
    @include for-small-tablet-below {
      width: 100%;
      justify-content: space-between;
    }
    &:hover {
      background-color: $black;
      color: $white;
    }
    .register {
      margin-left: 3rem;
    }
  }
  .banner-info-seperator {
    padding: 0 1rem;
    font-family: $heading-font;
    font-size: 1.5rem;
    @include for-small-tablet-below {
      display: none;
    }
  }
}

/* BLOCKS
---------------------------------------------- */
.block-type-centredtext {
  >div>* {
    margin-right: auto;
    margin-left: auto;
    &:not(:first-child) {
      margin-top: 4.64rem;
    }
  }
  .media {
    width: 400px;
    @include media;
    @include for-small-tablet-below {
      width: 100%;
    }
  }
  video {
    width: 100%;
    height: auto;
  }
  p {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
  }
  .button {
    display: flex;
    justify-content: center;
    a {
      display: block;
      font-family: $heading-font;
      font-size: var(--medium-font-size);
      font-style: italic;
    }
  }
}

.block-type-ctawithmedia {
  .split-content {
    @include split-content;
  }
  .media {
    @include split-media;
  }
  .text-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: calc(var(--lr-page-padding)*2.5) var(--lr-page-padding) var(--lr-page-padding) var(--lr-page-padding);
    @include for-small-tablet-below {
      margin: var(--medium-vertical-margin) 0;
    }
    h2 {
      margin-bottom: 1rem;
    }
    img {
      width: 170px;
      height: auto;
      margin-top: 3rem;
    }
    .button {
      margin-top: 2rem;
      text-transform: uppercase;
    }
    .byline {
      font-size: 1.57rem;
      margin-top: 5rem;
    }
  }
}

.block-type-textwithmedia, .section-type-textwithmedia {
  .split-content {
    @include split-content;
  }
  .media {
    @include split-media;
  }
  .text-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--lr-page-padding);
    @include for-small-tablet-below {
      margin: var(--medium-vertical-margin) 0;
    }
    h1, h2 {
      max-width: 500px;
      @include for-large-desktop-up {
        max-width: initial;
      }
    }
    p, ul, li {
      max-width: 475px;
      // margin-left: auto;
      // margin-right: auto;
      @include for-large-desktop-up {
        max-width: initial;
      }
      &:first-of-type {
        margin-top: 2rem;
      }
    }
    .button {
      margin-top: 2rem;
      text-transform: uppercase;
    }
    &.left-alignment {
      align-items: start;
      text-align: initial;
      >div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

.block-type-textwithmedia, .section-type-textwithmedia, .block-type-overview .overview-text {
  ul, ol {
    list-style-type: initial;
    margin-block-start: 1em;
    margin-inline-start: 1.2em;
    li:not(:last-of-type) {
      margin-bottom: .5rem;
    }
  }
  ul.flickity-page-dots, ol.flickity-page-dots  {
    list-style-type: none;
    margin-block-start: 0px;
    margin-inline-start: 0px;
    li:not(:last-of-type) {
      margin-bottom: initial;
    }
  }
}

.block-type-cta {
  h2 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .square-button {
    margin-top: 2rem;
    @include square-button;
  }
}

.block-type-widetextwithmedia {
  >div {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: calc(var(--lr-page-padding) / 2);
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
    }
  }
  >div>.media {
    @include for-small-tablet-below {
      margin-top: var(--small-vertical-margin);
    }
  }
  >div>div>div p {
    line-height: 120%;
  }
  >div>div>div p:not(:first-of-type) {
    font-family: $body-font;
    font-size: 1.14rem;
    line-height: initial;
  }
  .button {
    margin-top: 1.5rem;
  }
  img {
    max-width: 140px;
    float: right;
    @include for-small-tablet-below {
      float: left;
    }
  }
}

.block-type-threecolumngallery {
  >div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: calc(var(--lr-page-padding) / 2);
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      gap: var(--lr-page-padding);
      display: block;
      width: 100%;
      >div {
        width: 100%
      }
    }
    /* disable Flickity for small devices */
    &:after {
      content: 'flickity';
      display: none; /* hide :after */
    }
    @include for-tablet-landscape-up {
      /* enable Flickity */
      &:after {
        content: '';
      }
    }
  }
  @include object-fit-video;
  video,
  img {
    width: 100%;
    aspect-ratio: 1 / 1.3;
    object-fit: cover;
    @include for-small-tablet-below {
      width: calc(100vw - calc(var(--lr-page-padding) * 2));
    }
  }
  .flickity-page-dots {
    display: flex;
    justify-content: center;
    bottom: 2.5rem;
    width: calc(100% - calc(var(--lr-page-padding) * 2));
  }
  .flickity-prev-next-button.previous {
    left: 20px;
  }
  .flickity-prev-next-button.next {
    right: 20px;
  }
} 


.block-type-textoverimage {
  .background-image {
    position: relative;
    color: $white;
    img {
      height: 100vh;
      object-fit: cover;
    }
    .text-overlay {
      position: absolute;
      bottom: 0px;
      width: 100%;
      .inline-large-text {
        padding: var(--lr-page-padding) var(--lr-page-padding) 0 var(--lr-page-padding);
        flex-wrap: wrap;
        grid-area: 1/1/span 3/span 2;
        align-content: center;
        align-items: center;
        gap: 0;
        font-family: $heading-font;
        font-size: 7.5vw;
        line-height: 1.2;
        display: inline-flex;
        @include for-small-tablet-below {
          display: grid;
          grid-template-columns: 1fr;
          gap: var(--lr-page-padding);
          font-size: var(--x-large-font-size);
          justify-items: center;
          margin-bottom: var(--x-large-vertical-margin);
          .inset-image,
          .inset-text {
            order: 2;
          }
        }
        .second-line {
          padding-left: 15vw;
          @include for-small-tablet-below {
            padding-left: 0vw;
            order: 0;
            display: block;
          }
        }
        .inset-image {
          width: 6vw;
          height: auto;
          position: relative;
          margin: 0 2rem;
          margin-bottom: 0 2rem;
          @include for-small-tablet-below {
            width: 20vw;
            margin: 0 0rem;
          }
          img {
            height: auto;
          }
        }
        .inset-text {
          width: 20vw;
          @include for-small-tablet-below {
            width: initial;
            text-align: center;
            margin-bottom: var(--small-vertical-margin);
          }
        }
      }
      .square-button {
        @include square-button;
        padding: var(--lr-page-padding);
        text-align: center;
        a {
          border-color: $white;
          width: 100%;
          &:hover {
            color: $black
          }
        }
      }
    }
  }
}

.block-type-largeheading {
  >div>div {
    margin-top: 2rem;
  }
}

.block-type-hoveritems {
  >div {
    padding: 0 var(--lr-page-padding);
    >div {
      border-left: 1px solid $lines;
    }
  }
  h2 {
    padding: calc(var(--lr-page-padding) * 3.5) var(--lr-page-padding) calc(var(--lr-page-padding) * 2) var(--lr-page-padding);
    text-align: center;
    max-width: 505px;
    margin-right: auto;
    margin-left: auto;
    @include for-small-tablet-below {
      padding-top: var(--large-vertical-margin);
      padding-bottom: var(--medium-vertical-margin);
    }
  }
  .hover-container {
    display: grid;
    grid-template-columns: 1.6fr 1fr;
    gap: var(--lr-page-padding);
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      gap: initial;
    }
    .hover-titles-container {
      padding-bottom: calc(var(--large-vertical-margin) / 2);
      &:after {
        content: '';
      }
      @include for-small-tablet-below {
        padding-bottom: var(--large-vertical-margin);
        &:after {
          content: 'flickity';
          display: none; /* hide :after */
        }
      }
      .flickity-page-dots {
        bottom: 20px;
        display: flex;
        justify-content: center;
      }
      .hover-title {
        display: flex;
        align-items: center;
        @include for-small-tablet-below {
          display: grid;
          grid-template-columns: .08fr 1fr;
        }
        &.active {
          cursor: default;
          .line {
            width: 80px;
            @include for-small-tablet-below {
              width: 28px;
            }
          }
        }
        .line {
          width: 28px;
          height: 1px;
          background: $lines;
          transition: width .4s ease-in-out;
          @include for-small-tablet-below {
            flex-grow: 1;
          }
        }
        .hover-title-content {
          @include for-small-tablet-below {
            padding-bottom: 1.5rem;
          }
          .media img {
            @include for-small-tablet-below {
              padding-bottom: 1.5rem;
              width: 85%;
            }
          }
          .hover-title-content-for-mob {
            display: none;
            @include for-small-tablet-below {
              display: block;
            }
          }
        }
        h3 {
          padding: 1.5rem .5rem;
          max-width: 45vw;
          @include for-small-tablet-below {
            max-width: initial;
            padding-left: 0rem;
            padding-right: 0rem;
          }
        }
      }
    }
    .hover-content-container {
      margin-bottom: calc(var(--large-vertical-margin) / 2);
      @include for-small-tablet-below {
        display: none;
      }
      .hover-content {
        display: none;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        height: 100%;
        .media {
          img {
            width: 280px;
            @include for-small-tablet-below {
              width: 100%;
            }
          }
        }
        .hover-content-text {
          text-align: right;
          padding-left: 50px;
        }
      }
    }
  }
}

.block-type-scrollingoverview {
  .light-text {
    color: $white;
    .text-side {
      border-left: 1px solid $white;
      @include for-small-tablet-below {
        border-left: none;
      }
    }
    &.media-right {
      .text-side {
        border-right: 1px solid $white;
        @include for-small-tablet-below {
          border-right: none;
        }
      }
    }
    .button {
      a {
        text-decoration-color: $white;
        &:hover{
          text-decoration-color: rgb(0, 0, 0, 0);
        }
      }
    }
  }
  >div {
    padding: 0 var(--lr-page-padding);
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
    }
    .text-side {
      position: sticky;
      top: var(--lr-page-padding);
      align-self: start;
      height: calc(100vh - calc(var(--lr-page-padding)*2));
      margin: var(--lr-page-padding) 0;
      border-left: 1px solid $black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: calc(var(--lr-page-padding)*2.5) var(--lr-page-padding) var(--lr-page-padding) var(--lr-page-padding);
      @include for-small-tablet-below {
        position: relative;
        border-left: none;
        height: initial;
        margin: var(--large-vertical-margin) 0 var(--small-vertical-margin) 0;
        padding: var(--lr-page-padding);
        order: 0;
      }
      h2 {
        margin-bottom: 1rem;
      }
      img {
        width: 170px;
        height: auto;
        margin-top: 3rem;
      }
      .button {
        margin-top: 2rem;
        text-transform: uppercase;
      }
    }
    .scrolling-container {
      @include for-small-tablet-below {
        order: 1;
      }
      .scrolling-item {
        height: 100vh;
        padding-right: var(--lr-page-padding);
        @include for-small-tablet-below {
          height: initial;
          margin: var(--x-large-vertical-margin) 0;
        }
        &-icon {
          max-width: 150px;
          margin: 0 auto 2rem;
          @include for-small-tablet-below {
            max-width: 100px;
          }
        }
        h3 {
          max-width: 500px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        p:first-of-type {
          margin-top: 1rem;
          max-width: 500px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .media-right {
    .text-side {
      border-left: initial;
      border-right: 1px solid $black;
    }
    .scrolling-item {
      padding-right: initial!important;
      padding-left: var(--lr-page-padding);
      @include for-small-tablet-below {
        padding-left: initial!important;
      }
    }
  }
}

.block-type-overview {
  >div {
    padding: var(--lr-page-padding);
    @include for-small-tablet-below {
      padding-top: calc(var(--lr-page-padding) * 2);
    }
    .overview-top {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: calc(var(--lr-page-padding) / 2);
      margin-bottom: var(--medium-vertical-margin);
      @include for-small-tablet-below {
        grid-template-columns: 1fr;
        gap: calc(var(--lr-page-padding) * 2);
        margin-bottom: .5rem;
      }
      >div:first-of-type {
        max-width: 625px;
      }
      img {
        max-width: 140px;
        float: right;
        @include for-small-tablet-below {
          float: initial;
          max-width: 50vw;
        }
      }
    }
    .overview-bottom {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: calc(var(--lr-page-padding) / 2);
      margin-bottom: 1rem;
      align-items: end;
      @include for-small-tablet-below {
        grid-template-columns: 1fr;
        gap: calc(var(--lr-page-padding) * 2);
        margin-bottom: var(--small-vertical-margin);
      }
      .overview-text {
        max-width: calc(50vw - var(--lr-page-padding));
        @include for-small-tablet-below {
          max-width: initial;
        }
        margin-top: .7rem;
      }
      .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        text-align: right;
        @include for-small-tablet-below {
          margin-top: var(--medium-vertical-margin);
        }
        h3 {
          max-width: 150px;
          margin-bottom: 5rem;
          @include for-small-tablet-below {
            margin-bottom: var(--small-vertical-margin);
            max-width: 250px;
          }
        }
      }
    }
    .square-button {
      @include square-button;
      padding-top: 1rem;
      text-align: center;
      @include for-small-tablet-below {
        padding-top: initial;
      }
      a {
        width: 100%;
      }
    }
  }
}
.overview-price {
  font-weight: 600;
  margin-top: 2rem;
}

.block-type-largetestimonials {
  h2 {
    font-size: var(--xx-large-font-size);
    max-width: 60vw;
    text-align: center;
    margin: 0 auto var(--large-vertical-margin);
    @include for-small-tablet-below {
      max-width: initial;
      padding-left: var(--lr-page-padding);
      padding-right: var(--lr-page-padding);
    }
  }
  h3 {
    font-size: 1.57rem;
    margin-bottom: 7rem;
  }
  .cell {
    width: 30%;
    padding: 0 7vw;
    @include for-small-tablet-below {
      width: 100%;
    }
    >div {
      border: 1px solid $black;
      transition: background-color .4s ease-in-out;
      padding: 3rem 3rem;
      height: 100%;
      .testimonial-content {
        height: 100%;
        width: 20vw;
        @include for-small-tablet-below {
          width: 100%;
        }
      }
    }
    &.is-selected>div{
      background-color: $grey;
    }
  }
  .large-testimonials-carousel-buttons {
    display: flex;
    margin: 1rem auto 0 auto;
    justify-content: center;
    .chevron {
      padding: 1rem
    }
    .carousel-status-container {
      width: 35px;
      .carousel-status {
        padding: 1rem 0;
      }
    }
  }
  .flickity-slider { 
    height: 100%; 
    & > div { 
    min-height: 100%; } 
  }
}

.block-type-testimonials {
  @include for-small-tablet-below {
    p:last-of-type {
      padding-bottom: 2rem;
    }
  }
  >div:first-of-type {
    @include for-small-tablet-below {
      padding-bottom: var(--large-vertical-margin);
    }
  }
  h2 {
    max-width: 500px;
  }
  h3 {
    max-width: 180px;
    margin-top: 5rem;
  }
  .cell {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .testimonial-content {
    margin-top: 2rem;
    p {
      max-width: 1000px;
      padding-right: 50px;
    }
  }
  .flickity-prev-next-button.previous {
    display: none;
  }
  .flickity-prev-next-button.next {
    right: 0px;
  }
}

.block-type-carousel {
  .phase-button-group {
    z-index: 1;
    position: absolute;
    right: 0;
    display: none;
    justify-content: space-between;
    padding: 0 calc(var(--lr-page-padding) / 2);
    @include for-tablet-landscape-up {
      display: flex;
    }
  }
  .phase-button-group > button {
    padding: calc(var(--lr-page-padding) / 2);
  }
  .phase-button-group > button.active-button {
  }
  .phase-carousel {
    .phase-cell {
      padding-bottom: var(--x-large-vertical-margin);
      @include for-tablet-landscape-up {
        padding-bottom: initial;
      }
    }
    .phase-header {
      .phase-number {
        display: block;
        margin-bottom: 2rem;
        @include for-tablet-landscape-up {
          display: none
        }
      }
      h2, p {
        max-width: initial;
        @include for-tablet-landscape-up {
          max-width: calc(50vw - var(--lr-page-padding));
        }
      }
      h2 {
        margin-bottom: 1rem;
      }
    }
    /* disable Flickity for small devices */
    &:after {
      content: '';
    }
    @include for-tablet-landscape-up {
      /* enable Flickity */
      &:after {
        content: 'flickity';
        display: none; /* hide :after */
      }
    }
  }
  .phase-content-carousel {
    /* enable Flickity by default */
    &:after {
      content: 'flickity';
      display: none; /* hide :after */
    }
    @include for-tablet-landscape-up {
      display: flex;
      /* disable Flickity for large devices */
      &:after {
        content: '';
      }
      &.grid-4 {
        img {
          height: 16vw;
        }
      }
      &.grid-3 {
        img {
          height: 22vw;
        }
      }
      &.grid-2 {
        img {
          height: 32vw;
        }
      }
    }
    .flickity-page-dots {
      bottom: -40px;
      display: flex;
      justify-content: center;
    }
    img {
      object-fit: cover;
      height: 50vw;
      width: auto;
      aspect-ratio: 1.6 / 1;
      @include for-small-tablet-below {
        width: 100%;
        aspect-ratio: initial;
      }
    }
    .phase-content {
      margin-top: 4rem;
      .phase-text-content {
        padding-top: 2rem;
        >* {
          padding-left: var(--lr-page-padding);
          padding-right: 3rem;
        }
        >div {
          border-bottom: 1px solid $black;
          padding-bottom: 1rem;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: 1.14rem;
        }
        p:first-of-type {
          margin-top: 1rem;
        }
      }
    }
  }
}

.block-type-retreats {
  .retreats-heading {
    text-align: center;  
    padding-left: var(--lr-page-padding);
    padding-right: var(--lr-page-padding);
    @include for-small-tablet-below {
      padding-left: calc(var(--lr-page-padding)*2);
      padding-right: calc(var(--lr-page-padding)*2);
    }
    h2 {
      line-height: 1.2;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .block-type-overview {
    .retreat-overview-details {
      margin-top: 1rem;
      display: flex;
      gap: 5rem;
      @include for-small-tablet-below {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--lr-page-padding);
        div {
          order: 1;
        }
        div:last-of-type {
          order: 0;
        }
      }
    }
    .overview-bottom {
      min-height: 200px;
      @include for-small-tablet-below {
        min-height: initial;
      }
    }
  }
}

.block-type-faqs {
    .faqs-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--lr-page-padding);
      @include for-small-tablet-below {
        grid-template-columns: 1fr;
        gap: calc(var(--lr-page-padding)*3);
      }
      h3 {
        padding-right: 6rem;
      }
      .accordion {
        display: flex;
        flex-direction: column;
        height: auto;
        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
        .accordion-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          .accordion-btn {
            margin: 0;
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            >* {
              pointer-events: none;
            }
            .cross {
              position: relative;
              height: 30px;
              width: 30px;
              padding-left: 10px;
              pointer-events: none;
              padding-top: .5em;
              transition: opacity .3s;
              float: right;
              @include for-small-tablet-below {
                padding-top: .4em;
              }
              &:after {
                content: '';
                width: 30px;
                height: 1px;
                background-color: $black;
                position: absolute;
                @include for-small-tablet-below {
                  width: 18px;
                }
              }
              &:before {
                content: '';
                width: 30px;
                height: 1px;
                background-color: $black;
                position: absolute;
                transform: rotate(90deg);
                transition: all 0.3s ease-in-out;
                @include for-small-tablet-below {
                  width: 18px;
                }
              } 
            }
          }
          .accordion-panel {
            transition: all 0.4s;
            opacity: 0;
            height: auto;
            max-height: 0;
            overflow: hidden;
            font-size: .7em
          }
          &.active {
            .cross::before {
              transform: rotate(0deg);
            }
            .accordion-panel {
              padding: 1rem 0 15px 0;
              opacity: 1;
              max-height: 2000px;
            } 
          }
        }
      }
    }
  }

/* FOOTER
---------------------------------------------- */
.footer {
  margin-top: var(--large-vertical-margin);
  margin-bottom: 2rem;
  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    @include for-small-tablet-below {
      flex-direction: column;
      grid-gap: calc(var(--lr-page-padding)*2)
    }
    .footer-menus {
      display: flex;
      @include for-small-tablet-below {
        flex-direction: column;
        grid-gap: calc(var(--lr-page-padding)*2)
      }
      .footer-menu:not(:first-of-type) {
        margin-left: 6.21rem;
        @include for-small-tablet-below {
          margin-left: initial;
        }
        
      }
    }
  }
  .footer-title {
    margin-bottom: .5rem;
  }
  ul a {
    padding: 2px 0;
    display: block;
  }
  .newsletter-byline {
    padding: 2px 0;
  }
}
.colophon {
  display: flex;
  margin-top: 4.28rem;
  @include for-small-tablet-below {
    flex-direction: column;
    grid-gap: calc(var(--lr-page-padding)*2)
  }
  div:first-of-type {
    margin-right: var(--lr-page-padding);
  }
}

/* RETREATS PAGE
---------------------------------------------- */
.retreats-template {
  .banner {
    min-height: 92vh;
    h1 {
      text-align: center;
      margin-bottom: 4rem;
      @include for-small-tablet-below {
        margin-bottom: var(--small-vertical-margin);
      }
    }
    img {
      width: 50vw;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1.5 / 1;
      @include for-small-tablet-below {
        width: 70vw;
      }
    }
    .retreat-images {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: calc(var(--lr-page-padding)/2);
      img {
        width: 25vw;
        aspect-ratio: 1.3 / 1;
        height: auto;
        object-fit: cover;
        @include for-small-tablet-below {
          width: 33vw;
        }
      }
    }
    .retreat-locations {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      min-width: 60vw;
      @include for-small-tablet-below {
        margin-top: var(--small-vertical-margin);
      }
      .retreat-location {
        @include for-small-tablet-below {
          text-align: center;
          span:first-of-type {
            display: block;
          }
        }
      }
      .line {
        flex-grow: 1;
        height: 1px;
        background: $black;
        margin: 0 1rem;
      }
    }
  }
  
}

/* RETREAT
---------------------------------------------- */
.retreat-template {
  .section-type-textwithmedia {
    .text-side {
      align-items: initial;
      justify-content: flex-end;
      h1 {
        max-width: initial;
      }
      p {
        margin: initial;
        margin-top: 2rem;
      }
    }
    .media-right .media-padding {
      @include for-small-tablet-below {
        padding: initial;
        .media {
          img {
            width: 100%;
            height: auto;
            aspect-ratio: 1 / 1;
          }
        }
      }
    }
    .media {
      img {
        width: 100%;
        height: 83vh;
      }
    }
    .square-button {
      @include square-button;
      padding-top: 2rem;
    }
  }
}
.hotel-carousel {
  .cell {
    width: 100%;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1 / 1;
  }
}
.rooms {
  .rooms-header {
    h2 {
      margin-bottom: 1rem;
      max-width: calc(50vw - var(--lr-page-padding));
    }
    p {
      max-width: calc(50vw - var(--lr-page-padding));
    }
  }
  .room {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: var(--lr-page-padding);
    align-items: end;
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      gap: calc(var(--lr-page-padding)*2);
    }
    .room-text {
      max-width: 30vw;
      p {
        margin-top: .5rem;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: var(--lr-page-padding);
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1.3 / 1;
    }
  }
}
.extra-info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: var(--lr-page-padding);
  @include for-small-tablet-below {
    grid-template-columns: 1fr;
    gap: calc(var(--lr-page-padding)*2);
  }
  .extra-info-header {
    h2 {
      margin-bottom: 1rem;
    }
  }
}

.last-block--page-color {
  padding-bottom: 0px;
}
/* REGISTRATION PAGE
---------------------------------------------- */
.registration-template, .form-template {
  .container {
    margin-top: 4rem;
    margin-bottom: var(--x-large-vertical-margin);
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      gap: calc(var(--lr-page-padding)*2);
    }
    .info-side {
      position: sticky;
      top: 4rem;
      align-self: start;
      height: calc(100vh - 9rem);
      margin-top: var(--lr-page-padding);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include for-small-tablet-below {
        position: relative;
        top: initial;
        height: initial;
      }
      .registration-heading {
        display: block;
        margin-bottom: 1rem;
        line-height: 100%;
      }
      .registration-info-footer {
        @include for-small-tablet-below {
          margin: var(--small-vertical-margin) 0;
          display: grid;
          gap: var(--lr-page-padding);
          grid-template-columns: 1fr 1fr;
          align-items: end;
          .registration-info-footer-text {
            order: 2;
          }
        }
      }
      img {
        height: 200px;
        width: auto;
        margin-top: 1rem;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        @include for-small-tablet-below {
          width: 100%;
          height: auto;
          margin-top: 0rem;
        }
      }
    }
  }
  .formblock__submit {
    position: relative;
    width: fit-content;
    text-align: right;
    margin-left: auto;
    &>input {
      background-color: initial;
      color: $black;
      font-family: $heading-font;
      font-style: italic;
      font-size: var(--medium-font-size);
      @include for-small-tablet-below {
        font-size: var(--large-font-size);
      }
    }
  }
}

/* FORM PAGE
---------------------------------------------- */
.form-template {
  .container {
    .info-side {
      height: calc(100vh - 12rem);
    }
  }
}

/* GALLERY PAGE
---------------------------------------------- */
.gallery-template {
  .gallery-container {
    position: relative;
    padding-bottom: var(--medium-vertical-margin);
    height: 100%;
    .gallery {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
      row-gap: 40px;
      padding-top: var(--medium-vertical-margin);
      @include for-tablet-landscape-up {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 95px;
        row-gap: 115px;
        padding-top: var(--medium-vertical-margin);
        .gallery-item {
          grid-column: span 2;
          &:nth-child(7) {
            grid-column: 2 / span 2;
          }
          &:nth-child(12) {
            grid-column: 3 / span 2;
          }
          &:nth-child(16) {
            grid-column: 4 / span 2;
          }
          &:nth-child(19) {
            grid-column: 5 / span 2;
          }
          &:nth-child(21) {
            grid-column: 6 / span 2;
          }
        }
      }
      @include object-fit-video;
      img,
      video {
        width: 100%;
        aspect-ratio: 1 / 1.3;
        height: auto;
        object-fit: cover;
      }
    }
    svg {
      position: absolute;
      z-index: -1;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .block-type-centredtext {
    margin-bottom: var(--x-large-vertical-margin);
  }
}

/* CONTACT PAGE
---------------------------------------------- */
.contact-template {
  .block-type-centredtext>div>*:not(:first-child) {
    margin-top: 3rem;
  }
  .square-button-container {
    grid-template-columns: 1fr 1fr;
    max-width: 650px;
    @include for-small-tablet-below {
      margin-top: var(--large-vertical-margin)!important;
      grid-template-columns: 1fr;
      gap: calc(var(--lr-page-padding)/1);
    }
    .square-button {
      @include square-button;
      margin-top: 1rem;
      @include for-small-tablet-below {
        margin-top: 0rem;
      }
      a {
        width: 100%;
        padding-right: 3rem;
        padding-left: 3rem;
      }
    }
  }
  .contact-form {
    margin-top: var(--medium-vertical-margin);
    @include for-small-tablet-below {
      
    }
    .formblock {
      max-width: 1200px;
    }
    .formblock_field__label {
      text-align:center;
    }
    .formfield__input,
    .formfield__select,
    .formfield__radio,
    .formfield__textarea {
      text-align:center
    }
    .formblock__submit {
      margin-right: auto;
      &>input {
        color: $black;
        font-family: $heading-font;
        font-size: var(--medium-font-size);
        font-style: italic;
        background: transparent;
      }
    }
  }
} 

/* DEFAULT PAGE
---------------------------------------------- */
.default-template {
  .banner {
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    .heading {
      text-align: center;
      max-width: 700px;
      h1 {
        margin-bottom: 1.5rem
      }
      .button {
        margin-top: 3.5rem;
        a {
          text-transform: uppercase;
        }
      }
    }
    &--light {
      color: $white;
      .heading {
        .button {
          a {
            text-decoration-color: $white;
            &:hover{
              text-decoration-color: rgba(255, 255, 255, 0);
            }
          }
        }
      }
    }
  }
  .background-image {
    position: relative;
    height: 100vh;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

/* ERROR PAGE
---------------------------------------------- */

.error-template {
  .banner {
    margin-top: 25vh;
    margin-bottom: 25vh;
  }
}

// @media screen and (max-width: 1000px) {
//   html {
//     display: none
//   }
// }