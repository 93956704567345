/* SCSS VARIABLES
---------------------------------------------- */
$black: #1D1D1B;
$white: white;
$grey: #F0F0F0;
$lines: #CDC9BE;
$heading-font: 'Saol', serif;
$body-font: 'Inter', sans-serif;

/* CSS CUSTOM PROPERTIES
---------------------------------------------- */
:root {
  --vh: 1;
  --font-size: 14px;

  --medium-font-size: 2.285rem;
  --large-font-size: 4.428rem;
  --x-large-font-size: 5.857rem;
  // --xx-large-font-size: clamp(5rem, 1.429rem + 9.524vw, 10rem);
  --xx-large-font-size: 8rem;

  --lr-page-padding: 30px;
  --small-vertical-margin: 2rem;
  --medium-vertical-margin: 6rem;
  --large-vertical-margin: 10rem;
  --x-large-vertical-margin: 12rem;

  --formblock-color: #1D1D1B;
  --formblock-color-invert: #1D1D1B;
  --formblock-color-error: #CC0000;
  --formblock-color-success: transparent;

  font-size: var(--font-size);
}

/* MOBILE CSS CUSTOM PROPERTIES
---------------------------------------------- */
@include for-small-tablet-below {
  :root {
    --vh: 1;

    --medium-font-size: 1.5rem;
    --large-font-size: 2rem;
    --x-large-font-size: 2.8rem;
    --xx-large-font-size: 3.5rem;

    --lr-page-padding: 13px;
    --small-vertical-margin: 2rem;
    --medium-vertical-margin: 3rem;
    --large-vertical-margin: 5rem;
    --x-large-vertical-margin: 7rem;
  }
}